import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-deleteuser',
  templateUrl: './deleteuser.component.html',
  styleUrls: ['./deleteuser.component.css']
})
export class DeleteuserComponent implements OnInit {
  event: EventEmitter<any> = new EventEmitter
    baseUrl: string;
  constructor(private router: Router, private bsModalRef: BsModalRef, private SpinnerService: NgxSpinnerService, private http: HttpClient, private toastr: ToastrService) {
    this.baseUrl = Global.apiURL;
  }

  ngOnInit(): void {
  }
  deleteUsers(id: number) {
    this.SpinnerService.show();
    this.bsModalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.SpinnerService.hide();
        setTimeout(() => {
          this.http.delete(this.baseUrl + '/deleteuser.php?id=' + id)
            .subscribe(data => {
              this.redirectTo('/users');
              this.toastr.success('User Deleted Successfully!', 'Success!');
            });
        }, 500);
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();
  }

  onClose() {
    this.bsModalRef.hide();
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/users']));
  }

}
