export class Addproject {
  constructor(
    public title: string,
    public user_id: number,
    public url: string,
    public description: string,
    public allow_pinterest: string,
    public budget: string,
    public image: string
  ) { }
}

