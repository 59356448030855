import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm,  } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from '../../common/global';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
 
  baseUrl: string;
  @Input() name: string;
  constructor(private SpinnerService: NgxSpinnerService, public location: Location, private router: Router, private modalService: NgbModal, private userservice: UserService, private toastr: ToastrService,private httpClient: HttpClient) { 
    this.baseUrl = Global.apiURL;

  }

  ngOnInit(): void {
  }

    
  contact = {
    name : '',
    email : '',
    subject : '',
    phone : '',
    message : ''
  };
  onSubmitcontact(form: NgForm) {

    if (this.contact.name == '') {
      this.toastr.error('Please enter your name', 'Error!');
    }
    else if (this.contact.email == '') {
      this.toastr.error('Please enter your email', 'Error!');
    }
    else if (this.contact.subject == '') {
      this.toastr.error('Please enter your subject', 'Error!');
    }

    else if (this.contact.message == '') {
      this.toastr.error('Please enter your message', 'Error!');
    }
    
    else{
      const data = {
        name: this.contact.name,
        email: this.contact.email,
          subject: this.contact.subject,
          phone: this.contact.phone,
          message: this.contact.message
      };
      this.httpClient.post<any>(this.baseUrl + "/contactus.php" , data).subscribe(
        (res: any) => {
          
            this.toastr.success('Welcome', 'Success!');
          
        },
        error => {
          
          console.log(success);
          this.toastr.success('Message sent Successfully!');
          this.router.navigate(['/home']);
        });
          // name: this.contact.name,
          // email: this.contact.email,
          // subject: this.contact.subject,
          // phone: this.contact.phone,
          // message: this.contact.message
                      }
                    }
                  }
                
              
            


function success(success: any) {
  throw new Error('Function not implemented.');
}

