import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router} from '@angular/router';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-deleteproject',
  templateUrl: './deleteproject.component.html',
  styleUrls: ['./deleteproject.component.css']
})
export class DeleteprojectComponent implements OnInit {
  event: EventEmitter<any> = new EventEmitter
  title: any;
    baseUrl: string;

  constructor(private router: Router, private bsModalRef: BsModalRef, private SpinnerService: NgxSpinnerService, private http: HttpClient, private toastr: ToastrService) {
    this.baseUrl = Global.apiURL;
  }

  deletePost(id: number) {
    this.bsModalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.SpinnerService.show();
        this.http.delete(this.baseUrl + '/deleteproject.php?id=' + id)
            .subscribe(data => {
              this.router.navigate(['/projectsall'])
              this.toastr.success('Project Deleted Successfully!', 'Success!');
            });
        this.SpinnerService.hide();
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();
  }

  onClose() {
    this.bsModalRef.hide();

  }
  ngOnInit(): void {
  }

}
