export class Register {
  constructor(
    public first_name: string,
    public last_name: string,
    public email: string,
    public password: string,
    public codebox: string,
    public credits: string,
  ) {}
}


export class ChangePassword {
  constructor(
    public id: number,
    public email: string,
    public oldpassword: string,
    public newpassword: string
  ) { }
}

export class ProfileDet {
  constructor(
    public id: number,
    public username: string,
    public email: string
  ) { }
}
