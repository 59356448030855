import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../common/global';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  submitted: boolean;
  baseUrl: string;

  constructor(public location: Location, private router: Router, private modalService: NgbModal, private userservice: UserService, private toastr: ToastrService, private httpClient: HttpClient) {
    this.baseUrl = Global.apiURL;
  }
  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  reset = {
    email : '',
    newpassword : '',
    conpassword : ''
  };

  onSubmitreset() {
    if (this.reset.newpassword == '') {
      this.toastr.error('Please your new password', 'Error!');
    }
    else if (this.reset.conpassword == '') {
      this.toastr.error('Please your confirm password', 'Error!');
    }
    else if (this.reset.newpassword != this.reset.conpassword) {
      this.toastr.error('New passowrd and confirm password does not match', 'Error!');
    }
    else {
      this.httpClient.get<any>(this.baseUrl + "/getuserdetailsemail.php?email=" + this.reset.email).subscribe(
        userresponse => {
          const datareset = {
            id: userresponse[0].id,
            email: userresponse[0].email,
            new_password: this.reset.newpassword
          };
          this.userservice.createreset(datareset).subscribe(
            response => {
              this.submitted = true;
              this.router.navigate(['/home']);
              this.toastr.success('Password Reset Successfully', 'Success!');
            },
            error => {
              console.log(error);
              this.toastr.error('Some Error Occurred', 'Error!');
            });
        },
        error => {
          console.log(error);
          this.toastr.error('Some Error Occurred', 'Error!');
        });
    }
  }

}
