import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangePassword, ProfileDet, Register } from '../model/register.model';
import { Login } from '../model/login.model';
import { Observable } from 'rxjs';
import { Global } from '../common/global';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  googleClientId="563572921203-j6qagqc5sc1d5152m2d6nvsp96nbk6i2.apps.googleusercontent.com";
  shareddatareg: string;
  shareddatalog: string;
  shareddatachangepass: string;
    baseUrlprofile: string;
  userprofiledet: ProfileDet[] = [];
    baseUrl: string;

  constructor(private http: HttpClient) {
    this.shareddatareg = '';
    this.shareddatalog = '';
    this.shareddatachangepass = '';
    this.baseUrl = Global.apiURL;
  }

  formdata: Register = new Register('','', '', '','','');
  formdatalogin: Login = new Login('', '');
  formdatachange: ChangePassword = new ChangePassword(0,'','', '');

  createLogin() {
    return this.http.post(this.baseUrl + '/login.php', this.formdatalogin);
  }
  createsignup() {
    return this.http.put(this.baseUrl + '/adduser.php', this.formdata);
  }

  createforget(userid) {
    return this.http.get<any>(this.baseUrl + '/forgetpassword.php?user_id=' + userid);
  }
  createreset(datareset) {
    return this.http.post(this.baseUrl + '/changepwd.php', datareset);
  }
  refreshstat() {
    const userid = localStorage.getItem("UserID");
    return this.http.get<any>(this.baseUrl + '/getuserdetails.php?id='+ userid);
  }
  referafriend(data) {
    return this.http.post(this.baseUrl + '/referafriend.php', data);
  }
  refercredits(data) {
    return this.http.post(this.baseUrl + '/refercredits.php', data);
  }


  ChangePassword() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '
    });
    return this.http.post(this.baseUrl + '/changepwd.php', this.formdatachange, { headers: reqHeader });
  }
}
