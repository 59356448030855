import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormsModule, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Categories } from '../../../model/categories.model';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-editlink',
  templateUrl: './editlink.component.html',
  styleUrls: ['./editlink.component.scss']
})
export class EditlinkComponent implements OnInit {
  linkid: number;
  baseUrlcat: string;
  catVals: Array<any> = [];
  catValsSelected: Array<any> = [];
  public getcat: Categories[];
  public MyprojForm: FormGroup;
  uniqueitems: Array<string> = [];
  public post: MyProj = {
    id: 0,
    keyword: '',
    description: '',
    link: '',
    budget: '',
  }
  projectscat: any;
  loading: boolean;
  imageurl: any;
  baseUrl: string;
  projectdetail: any;
  allstatdetail: any;
  statdatacred: any;

  constructor(private SpinnerService: NgxSpinnerService, private toastr: ToastrService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.baseUrl = Global.apiURL;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.get(this.baseUrl + '/getallcategories.php', { headers: reqHeader }).toPromise()
      .then((res: any) => {
        this.getcat = res as Categories[]
      },
      err => {
      }
    );
    this.route.queryParams.subscribe(params => {
      this.linkid = params['id'];

      var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json',});
      this.http.get(this.baseUrl + '/getlinkcategories.php?id=' + this.linkid, { headers: reqHeader }).toPromise().then((res: any) => {
        for (var i = 0; i < res.length; i++) {
          this.catValsSelected.push(res[i].cat_id);
        }
      },
        err => { }
      );
    });
    this.MyprojForm = this.fb.group({
      id: '',
      keyword: '',
      description: '',
      link: '',
      allow_pinterest: true,
      budget: [10, Validators.required],
    });
  }
  public get half(): number {
    return Math.ceil(this.getcat?.length / 2);
  }
  checkChecked(id) {
    var index = this.catValsSelected.indexOf(id, 0);
    if (index > -1)
    {
      this.catVals.push(id);
      return true;
    }else
      return false;
  }
  eventCheck(id, e) {
      var index = this.catVals.indexOf(id, 0);
      debugger;
    if (index > -1)
      this.catVals.splice(index, 1);
    else
      this.catVals.push(id);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.linkid = params['id'];
      const data = {}
      this.SpinnerService.show();
      this.http.post(this.baseUrl + '/getlinkdetails.php?id=' + this.linkid, data).subscribe((res: any) => {
        this.http.get(this.baseUrl + '/getlinkcategories.php?id=' + this.linkid).subscribe(data => {
          this.projectscat = data;
          this.post = res;
          
          if (this.post[0].allow_pinterest == true) {
            this.MyprojForm.controls["allow_pinterest"].setValue(this.post[0].allow_pinterest);
          }
          else {
            this.MyprojForm.controls["allow_pinterest"].setValue(this.post[0].allow_pinterest);
          }
          this.MyprojForm.controls["id"].setValue(this.post[0].id);
          this.MyprojForm.controls["keyword"].setValue(this.post[0].keyword);
          this.MyprojForm.controls["description"].setValue(this.post[0].description);
          this.MyprojForm.controls["link"].setValue(this.post[0].link);
          this.MyprojForm.controls["budget"].setValue(this.post[0].budget);
          this.SpinnerService.hide();
        },
        (err) => console.log(err),
          );
        });
    });
  }
  savemyProj() {
    debugger;
    let uniqueitems = this.catVals.filter((v, i, a) => a.indexOf(v) === i);
    if (uniqueitems.length > 3) {
      this.toastr.error("You can select maximum 3 categories.", 'Error!');
    }
    else if (!this.catVals.length) {
      this.toastr.error("Please select atleast one category.", 'Error!');
    }
    else {
      this.loading = true;
      this.http.get<any>(this.baseUrl + '/getlinkdetails.php?id=' + this.linkid).subscribe(
        (res: any) => {
          this.projectdetail = res;
          const datawiouimt = {
            id: this.MyprojForm.controls["id"].value,
            keyword: this.MyprojForm.controls["keyword"].value,
            description: this.MyprojForm.controls["description"].value,
            link: this.MyprojForm.controls["link"].value,
            allow_pinterest: this.MyprojForm.controls["allow_pinterest"].value,
            budget: this.MyprojForm.controls["budget"].value,
          }
          this.http.get<any>(this.baseUrl + "/getuserdetails.php?id=" + localStorage.getItem("UserID")).subscribe(
            (datastat: any) => {
              this.allstatdetail = datastat;
              const usercredits = this.allstatdetail[0].credits;
              const userbudget = datawiouimt.budget
                let uniqueElements = this.catVals.filter((v, i, a) => a.indexOf(v) === i);
                debugger;
                if (uniqueElements == this.catValsSelected) {}
                else {
                  this.http.delete<any>(this.baseUrl + "/deletelinkcategories.php?user=" + localStorage.getItem("UserID") + "&link_id=" + datawiouimt.id).subscribe();
                  for (var i = 0; i < uniqueElements.length; i++) {
                    this.http.get<any>(this.baseUrl + "/insertlinkscategories.php?cat=" + uniqueElements[i] + "&user=" + localStorage.getItem("UserID") + "&link_id=" + datawiouimt.id).subscribe();
                  }
                }
              if (res[0].budget == datawiouimt.budget) {

              }
              else {
                var minusbudne = datawiouimt.budget - res[0].budget;
                var attempts = (parseInt(usercredits) - minusbudne);
                localStorage.setItem("UserCredits", attempts.toString());
                const updatecreddata = {
                  credits: localStorage.getItem("UserCredits"),
                  id: localStorage.getItem("UserID")
                }
                this.http.post<any>(this.baseUrl + "/updateusercredits.php", updatecreddata).subscribe((dataprojcat: any) => {
                  this.statdatacred = dataprojcat;
                  const adduserstats = {
                    proj_id: datawiouimt.id,
                    user_id: localStorage.getItem("UserID"),
                    credits_in: 0,
                    credits_out: datawiouimt.budget
                  }
                  this.http.post<any>(this.baseUrl + "/adduserstats.php", adduserstats).subscribe((dataprojstat: any) => {
                  },
                    error => {
                      console.log(error);
                    });
                },
                  error => {
                    console.log(error);
                  });
              }
                this.http.put(this.baseUrl + '/updateuserlink.php', datawiouimt).subscribe(
                  (res: any) => {
                    this.post = res;
                    this.redirectTo('/mylinks');
                    this.toastr.success('Link Updated Successfully!', 'Success!');
                  },
                  (err) => console.log(err),
                );
            },
            error => {
              console.log(error);
            }
          );
        });
     
    }
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/mylinks']));
  }
  }
  export interface MyProj {
  id: number;
  keyword: string;
  description: string;
  link: string;
  budget: string;
}
