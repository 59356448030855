import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm, } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from '../../common/global';
import { HttpClient } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-referafriend',
  templateUrl: './referafriend.component.html',
  styleUrls: ['./referafriend.component.scss']
})
export class ReferafriendComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  baseUrl: string;
  data: Array<string> = [];
  guid: string;
  username: string;
  customurl:string;
  @Input() name: string;
  constructor(private clipboardApi: ClipboardService,private formBuilder: FormBuilder, private SpinnerService: NgxSpinnerService, public location: Location, private router: Router, private modalService: NgbModal, private userservice: UserService, private toastr: ToastrService, private httpClient: HttpClient) {
    this.baseUrl = Global.apiURL;

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
    });
    //localStorage.getItem("FirstName");
    //localStorage.getItem("LastName");
    this.guid = localStorage.getItem("Guid");
    this.username = localStorage.getItem("FirstName") + ' ' + localStorage.getItem("LastName");
    this.customurl="https://zingbolt.com/#/register?guid="+localStorage.getItem("Guid");
  }
  get f() { return this.form.controls; }
  copyText() {
    this.clipboardApi.copyFromContent(this.customurl);
    this.toastr.success(this.customurl+' copied', 'Success');
  }

  ReferaFriend() {
    // if (this.contact.email == '') {
    //   this.toastr.error('Please enter your email', 'Error!');
    // }
    this.submitted = true;
    if (this.form.invalid) {
      this.toastr.error('Form, invalid.', 'Error');
      return;
    }
    let data1 = this.f.email.value;
    this.data = data1.split(',');
    for (var i = 0; i < this.data.length; i++) {
      console.log(this.data[i]);
      const dataok = {
        email: this.data[i],
        guid: this.guid,
        name: this.username,
      };
      this.SpinnerService.show();
      debugger;
      this.userservice.referafriend(dataok).subscribe(async (res: any) => {
          this.SpinnerService.hide();
          this.toastr.success(`Email sent Successfully to ` + dataok.email + ``, `Success!`);
          this.router.navigate(['/dashboard']);
        },
        error => {
          console.log(success);
          this.SpinnerService.hide();
          this.toastr.success(`Email sent Successfully to ` + dataok.email + ``, `Success!`);
          this.router.navigate(['/dashboard']);
        });
    }
  }
}





function success(success: any) {
  throw new Error('Function not implemented.');
}

