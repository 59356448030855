import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../common/global';

@Component({
  selector: 'app-shareproject',
  templateUrl: './shareproject.component.html',
  styleUrls: ['./shareproject.component.scss']
})
export class ShareprojectComponent implements OnInit {
    detdetail: any;
    projdetal: any;
    userboxdet: any;
  
  pinurl: '';
  data: any;
  id: string = '';
    statdetail: any;
    baseUrl: string;
  constructor(private SpinnerService: NgxSpinnerService, private toastr: ToastrService,private httpClient: HttpClient, private router: Router, private route: ActivatedRoute, private projservice: ProjectService) {
    this.baseUrl = Global.apiURL;
  }
  ngOnInit(): void {
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");

    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
  inserturl(pinurl, data) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.httpClient.get<any>(this.baseUrl + "/getprojectdetails.php?id=" + this.id).subscribe((dataprojdet: any) => {
        this.projdetal = dataprojdet;
        const userinboxdet = {
          user_message: "Congrats! Your Article Was Shared",
          user_id: this.projdetal[0].user_id,
          user_name: localStorage.getItem("FirstName") + localStorage.getItem("LastName")
        }
        this.httpClient.post<any>(this.baseUrl + "/addinboxmessage.php", userinboxdet).subscribe((userinbox: any) => {
          this.userboxdet = userinbox;
        },
          error => {
            console.log(error);
          });
        const addshareprojectdet = {
          proj_id : this.id,
          user_id: localStorage.getItem("UserID"),
          pin_url: pinurl
        }
        this.httpClient.post<any>(this.baseUrl + "/addshareprojdetails.php", addshareprojectdet).subscribe((dataprojcat: any) => {
          //addinboxmessage.php
        },
          error => {
            console.log(error);
          });
        const addsharedetail = {
          proj_id: this.id,
          user_id: localStorage.getItem("UserID"),
          name: localStorage.getItem("FirstName") +" "+ localStorage.getItem("LastName"),
          pin_url: pinurl,
          proj_title: dataprojdet[0].title,
          cost_pin: dataprojdet[0].budget,
          image: dataprojdet[0].image,
          report_abuse: 0,
          proj_status: dataprojdet[0].project_status,
          allow_pinterest: dataprojdet[0].allow_pinterest
        }
        this.httpClient.post<any>(this.baseUrl + "/addsharingdetails.php", addsharedetail).subscribe((dataprojcat: any) => {
        },
          error => {
            console.log(error);
          });
        this.httpClient.get<any>(this.baseUrl + "/getuserdetails.php?id=" + localStorage.getItem("UserID")).subscribe(
          (userdata: any) => {
            this.detdetail = userdata;
            var attempts = (parseInt(this.detdetail[0].credits) + 1);
            localStorage.setItem("UserCredits", attempts.toString());
            const updatecreddata = {
              credits: localStorage.getItem("UserCredits"),
              id: localStorage.getItem("UserID")
            }
            this.httpClient.post<any>(this.baseUrl + "/updateusercredits.php", updatecreddata).subscribe((dataprojcat: any) => {
            },
              error => {
                console.log(error);
              });
            
          },
          error => {
            this.SpinnerService.hide();
            console.log(error);
          }
        );
        if (pinurl.includes('www.pinterest.com')) {
          this.projservice.updateurl(this.id, pinurl, data).subscribe(response => {
            this.SpinnerService.show();
            const updateuserstats = {
              proj_id: this.id,
              user_id: localStorage.getItem("UserID"),
              credits_in: 1,
              credits_out: 0
            }
            this.httpClient.post<any>(this.baseUrl + "/adduserstats.php", updateuserstats).subscribe((dataprojstat: any) => {
            },
              error => {
                console.log(error);
              });
            this.toastr.success('You have earned 1 credit. You can spend your new credits to share your article!', 'Success!');
            this.redirectTo('/dashboard');
          },
            error => {
              console.log(error);
            });
        }
        else {
          this.toastr.error('Not a Pinterest URL!', 'Error!');
        }
      },
        error => {
          console.log(error);
        });
      
    });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/dashboard']));
  }
}
