import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ProjectsDisp } from '../../model/categories.model';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '../../services/project.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Global } from '../../common/global';
declare const google: any;

@Component({
  selector: 'app-sharingsdetail',
  templateUrl: './sharingsdetail.component.html',
  styleUrls: ['./sharingsdetail.component.scss']
})
export class SharingsdetailComponent implements OnInit {
  listdisproj: ProjectsDisp[] = [];
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userroledea: string;
  bsModalRef: BsModalRef;
  event: EventEmitter<any> = new EventEmitter
  dtTrigger: Subject<any> = new Subject<any>();
  baseUrl: string;
    listmyproj: any;
  listintproj: any;
  projArr: any[] = [];

  constructor(private toastr: ToastrService, private bsModalService: BsModalService, private httpClient: HttpClient, private SpinnerService: NgxSpinnerService, private router: Router,) {
    this.baseUrl = Global.apiURL;
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      "language": {
        "paginate": {
          "first": "«",
          "previous": "‹",
          "next": "›",
          "last": "»"

        }
      },
      pageLength: 100,
      info: false,
      processing: true,
      order: [[5, 'desc']],
      lengthMenu: [5, 10, 25, 50, 100]
    };
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");
    this.SpinnerService.show();
    this.httpClient.get<any>(this.baseUrl + "/getmyprojects.php?user=" + userid).subscribe(
      data => {
        this.listmyproj = data;
        this.listmyproj.forEach(element => {
        this.projArr.push(element.id);
        });
        this.httpClient.get<any>(this.baseUrl + "/getinterprojectdetails.php?proj_id=" + this.projArr).subscribe(
          (data: any) => {
            this.listintproj = data;
            this.SpinnerService.hide();
            this.dtTrigger.next();

          },
          error => {
            console.log(error);
          }
          );
      },
      error => {
        console.log(error);
      }
    );
    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }
    this.Deactiveproj();
  }
  Deactiveproj() {
    this.userroledea = localStorage.getItem("UserRole");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/myprojects']));
  }
  openimage(image) {
    if (image == '' || image == null || image == undefined) {
      window.open("https://zingbolt.com/phpapi/imagenotavaliable.jpg");
    }
    else {
      window.open(image);
    }
  }
}
