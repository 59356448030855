import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Global } from '../common/global';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = Global.apiURL;
  }

  getAllCats(): Observable<any> {
    return this.http.get(this.baseUrl+'/getallcategoriesAdmin.php');
  }
  updateCattoggle(id, data){
    debugger;
    return this.http.put(this.baseUrl + '/togglecategory.php?id=' + id, data);
  }
  getCategory(id): Observable<any> {
    return this.http.get(this.baseUrl +'/getcategorybyid.php?id='+id );
  }
  getAll(): Observable<any> {
    return this.http.get(this.baseUrl+'/getallsettings.php');
  }
  getByName(name): Observable<any> {
    return this.http.get(this.baseUrl + '/getsettingbyname.php?name=' + name);
  }
  get(id): Observable<any> {
    return this.http.get(this.baseUrl +'/getsettingbyid.php?id='+id);
  }
  update(id,data): Observable<any> {
    return this.http.put(this.baseUrl + '/updatesetting.php?id=' + id,data);
  }
  updateCat(data): Observable<any> {
    return this.http.put(this.baseUrl + '/updatecategory.php',data);
  }
  updatetoggle(id, data) {
    debugger;
    return this.http.put(this.baseUrl + '/togglesetting.php?id=' + id, data);
  }
  create(data): Observable<any> {
    return this.http.post(this.baseUrl +'/addsetting.php', data);
  }



  //delete(id): Observable<any> {
  //  return this.http.delete(`${baseUrl}/${id}`);
  //}

  //deleteAll(): Observable<any> {
  //  return this.http.delete(baseUrl);
  //}

  //findByTitle(title): Observable<any> {
  //  return this.http.get(`${baseUrl}?title=${title}`);
  //}
}
