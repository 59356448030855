import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormsModule, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {
  projid: number;
  public userForm: FormGroup;
  public post: MyUser = {
    id: 0,
    first_name: '',
    last_name: '',
    role: '',
    address: ''
  }
    baseUrl: string;
  constructor(private SpinnerService: NgxSpinnerService, private toastr: ToastrService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.userForm = this.fb.group({
      id: '',
      first_name: '',
      last_name: '',
      role: [''],
      address: ''
    });
    this.baseUrl = Global.apiURL;
  }
  oppoSuits: any = ['admin', 'user']

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projid = params['id'];
      const data = {}
      this.SpinnerService.show();
      this.http.post(this.baseUrl + '/getuserdetails.php?id=' + this.projid, data).subscribe(
        (res: any) => {
          this.post = res;
          this.userForm.controls["id"].setValue(this.post[0].id);
          this.userForm.controls["first_name"].setValue(this.post[0].first_name);
          this.userForm.controls["last_name"].setValue(this.post[0].last_name);
          this.userForm.controls["role"].setValue(this.post[0].role);
          this.userForm.controls["address"].setValue(this.post[0].address);
          this.SpinnerService.hide();
        },
        (err) => console.log(err),
      );
    });
  }
  saveuserForm() {
    const data = {
      id: this.userForm.controls["id"].value,
      first_name: this.userForm.controls["first_name"].value,
      last_name: this.userForm.controls["last_name"].value,
      role: this.userForm.controls["role"].value,
      address: this.userForm.controls["address"].value
    }
    this.http.post(this.baseUrl + '/updateuser.php', data).subscribe(res => {
      this.redirectTo('/users');
      this.toastr.success('User Updated Successfully!', 'Success!');
      },
      (err) => console.log(err),
    );
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/users']));
  }
}

export interface MyUser {
  id: number;
  first_name: string;
  last_name: string;
  role: string;
  address: string;
}
