import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buy-credits',
  templateUrl: './buy-credits.component.html',
  styleUrls: ['./buy-credits.component.css']
})
export class BuyCreditsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
