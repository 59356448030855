import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../../common/global';
import { UserService } from '../../services/user.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const GENERAL_ROUTES: RouteInfo[] = [
  { path: '/my-profile', title: 'My Profile', icon: 'ni ni-badge text-blue', class: 'text-blue' },
  { path: '/referafriend', title: 'Refer a Friend', icon: 'ni-single-02 text-blue', class: 'text-blue' },
  { path: '/projectsall', title: 'All Projects', icon: 'ni-planet text-red', class: 'text-red' },
  { path: '/linksall', title: 'All Links', icon: 'ni-planet text-red', class: 'text-red' },
    { path: '/categories', title: 'All Categories', icon: 'ni-books text-red', class: 'text-red' },
  { path: '/settings', title: 'Settings', icon: 'ni ni-settings-gear-65 text-red', class: 'text-red' },
  { path: '/users', title: 'Users', icon: 'ni-single-02 text-red', class: 'text-red' },
];
export const PINTEREST_ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-blue', class: 'text-blue' },
  { path: '/myprojects', title: 'My Projects', icon: 'ni ni-archive-2 text-blue', class: 'text-blue' },
  { path: '/projectadd', title: 'Add Project', icon: 'ni ni-fat-add text-blue', class: 'text-blue' },
  { path: '/interactions', title: 'Interactions', icon: 'ni-single-02 text-blue', class: 'text-blue' },
  { path: '/skipped-projects', title: 'Skipped', icon: 'ni-single-02 text-blue', class: 'text-blue' },
  { path: '/history', title: 'History', icon: 'ni-single-02 text-blue', class: 'text-blue' },
];
export const LINKS_ROUTES: RouteInfo[] = [
  { path: '/linksdashboard', title: 'Dashboard', icon: 'ni-tv-2 text-blue', class: 'text-blue' },
  { path: '/mylinks', title: 'My Links', icon: 'ni ni-archive-2 text-blue', class: 'text-blue' },
  { path: '/addlinks', title: 'Add Link', icon: 'ni ni-fat-add text-blue', class: 'text-blue' },
  { path: '/linksinteractions', title: 'Interactions', icon: 'ni-single-02 text-blue', class: 'text-blue' },
  { path: '/skipped-links', title: 'Skipped', icon: 'ni-single-02 text-blue', class: 'text-blue' },
  { path: '/linkshistory', title: 'History', icon: 'ni-single-02 text-blue', class: 'text-blue' },
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public general_menu: any[];
  public pinterest_menu: any[];
  public links_menu: any[];
  public isCollapsed = true;
  userrole: string;
  baseUrl: string;
  userdetail: any;
  pinBtn:boolean;
  linkBtn:boolean;

  constructor(public userservice:  UserService,private router: Router, private httpClient: HttpClient) {
    this.baseUrl = Global.apiURL;
    this.pinBtn=true;
   this.linkBtn=false;
  }

  ngOnInit() {
    this.userservice.refreshstat().subscribe(
      (datastat: any) => {
        this.userdetail = datastat;
      },
      error => {
        console.log(error);
      }
    );

    this.GetUserroles();
    this.general_menu = GENERAL_ROUTES.filter(menuItem => menuItem);
    if (this.userrole == "user") {
      // this.general_menu = this.general_menu.slice(0, 7);
      this.general_menu = this.general_menu.slice(0, 2);
    }
    this.pinterest_menu = PINTEREST_ROUTES.filter(menuItem => menuItem);
    this.links_menu = LINKS_ROUTES.filter(menuItem => menuItem);

    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
  collapsepinterest(){
    if(this.pinBtn)
    {
      this.pinBtn=false;
    }
    else{
      this.pinBtn=true;
      this.linkBtn=false;
    }
  }
  collapseulinks(){
    if(this.linkBtn)
    {
      this.linkBtn=false;
    }
    else{
      this.linkBtn=true;
      this.pinBtn=false;
    }
  }
  GetUserroles() {
    this.userrole = localStorage.getItem("UserRole");
  }
}
