import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { CommonModule, DatePipe} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { RegisterComponent } from './pages/register/register.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ShareprojectComponent } from './pages/shareproject/shareproject.component';
import { EditprojectComponent } from './pages/projectsall/editproject/editproject.component';
import { DeleteprojectComponent } from './pages/projectsall/deleteproject/deleteproject.component';
import { EditmyprojectComponent } from './pages/myprojects/editmyproject/editmyproject.component';
import { DeletemyprojectComponent } from './pages/myprojects/deletemyproject/deletemyproject.component';
import { DataTablesModule } from "angular-datatables";
import { DeleteuserComponent } from './pages/users/deleteuser/deleteuser.component';
import { EdituserComponent } from './pages/users/edituser/edituser.component';
import { UsersprofileComponent } from './pages/user-profile/usersprofile/usersprofile.component';
import { ItemComponent } from './pages/item/item.component';
import { BuyCreditsComponent } from './pages/buy-credits/buy-credits.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { QueueComponent } from './pages/queue/queue.component';
import { QueueeditprojectComponent } from './pages/queue/queueeditproject/queueeditproject.component';
import { QueuedeleteprojectComponent } from './pages/queue/queuedeleteproject/queuedeleteproject.component';
import { SharingsdetailComponent } from './pages/sharingsdetail/sharingsdetail.component';
import { HistoryComponent } from './pages/history/history.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { ContactUsComponent } from './pages/register/contact-us.component';
import { BrowserModule } from "@angular/platform-browser";
import { ReferafriendComponent } from './pages/referafriend/referafriend.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { ShareModule } from 'ngx-sharebuttons';
import { MylinksComponent } from './pages/mylinks/mylinks.component';
import { DeletemylinksComponent } from './pages/mylinks/deletemylinks/deletemylinks.component';
import { LinksallComponent } from './pages/linksall/linksall.component';
import { EditlinkComponent } from './pages/linksall/editlink/editlink.component';
import { DeletelinkComponent } from './pages/linksall/deletelink/deletelink.component';
import { LinksharingdetailsComponent } from './pages/linksharingdetails/linksharingdetails.component';
import { LinkskippedComponent } from './pages/linkskipped/linkskipped.component';
import { LinkshistoryComponent } from './pages/linkshistory/linkshistory.component';
import { GoogleSigninModule } from './google-signin-module';

@NgModule({
  imports: [JwPaginationModule,
    BrowserAnimationsModule,
    FormsModule, 
    ShareModule,
    DataTablesModule,
    BrowserModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    GoogleSigninModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    ReactiveFormsModule
    
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ShareprojectComponent,
    EditprojectComponent,
    DeleteprojectComponent,
    EditmyprojectComponent,
    DeletemyprojectComponent,
    DeletemylinksComponent,
    DeleteuserComponent,
    EdituserComponent,
    UsersprofileComponent,
    ItemComponent,
    BuyCreditsComponent,
    QueueComponent,
    MylinksComponent,
    LinkskippedComponent,
    LinksallComponent,
    LinksharingdetailsComponent,
    LinkshistoryComponent,
    EditlinkComponent,
    DeletelinkComponent,
    QueueeditprojectComponent,
    QueuedeleteprojectComponent,
    SharingsdetailComponent,
    HistoryComponent,
    ReferafriendComponent,
    InboxComponent,
    ContactUsComponent
    
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
