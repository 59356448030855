import { Router } from '@angular/router';
import { GoogleAuthService } from './google-signin.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-google-sso-login',
  templateUrl: './google-signin.component.html',
  styleUrls: ['./google-signin.component.css'],
  providers: [GoogleAuthService]
})
export class GoogleSigninComponent implements OnInit {
  imageURL: string;
  email: string;
  name: string;
  token: string;
  @Output() onSigninSuccess = new EventEmitter();
  @Input() clientId: string;
  logintext="";

  constructor(private auth: GoogleAuthService,private router: Router) { }

  ngOnInit() {
    var url=this.router.url;
    if (url.includes("login")) {
      this.logintext="sign in";
    }
    if (url.includes("register")) {
      this.logintext="sign up";
    }

    setTimeout(() => { this.googleAuthenticate() }, 50);
  }

  googleAuthenticate() {
    this.auth.authenticateUser(this.clientId, this.onSigninSuccess);
  }
}
