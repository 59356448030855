export class Addlinks {
  constructor(
    public keyword: string,
    public user_id: number,
    public link: string,
    public description: string,
    public allow_pinterest: string,
    public budget: string,

  ) { }
}

