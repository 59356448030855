import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-deletemyproject',
  templateUrl: './deletemyproject.component.html',
  styleUrls: ['./deletemyproject.component.css']
})
export class DeletemyprojectComponent implements OnInit {
  event: EventEmitter<any> = new EventEmitter
  title: any;
    baseUrl: string;

  constructor(private router: Router, private bsModalRef: BsModalRef, private http: HttpClient, private toastr: ToastrService) {
    this.baseUrl = Global.apiURL;
  }

  deletemyPost(id: number) {
    this.bsModalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        setTimeout(() => {
          this.http.delete(this.baseUrl + '/deleteproject.php?id=' + id)
            .subscribe(data => {
              this.router.navigate(['/dashboard'])
              this.toastr.success('Project Deleted Successfully!', 'Success!');
            });
        }, 500);
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();
  }

  onClose() {
    this.bsModalRef.hide();

  }

  ngOnInit(): void {
  }

}
