import { Component, EventEmitter, OnInit, OnDestroy, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Global } from '../../common/global';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { DeletelinkComponent } from './deletelink/deletelink.component';


@Component({
  selector: 'app-linksall',
  templateUrl: './linksall.component.html',
  styleUrls: ['./linksall.component.scss']
})

export class LinksallComponent implements OnInit {
  user_id: any;
  link_status: any;
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  links;
  allLinks;
  bsModalRef: BsModalRef;
  event: EventEmitter<any> = new EventEmitter
  userrole: string;
  dtTrigger: Subject<any> = new Subject<any>();
  baseUrl: any;
  user: string;
  linksUnique;
  gridCheckAll: boolean = false;
  checkedProjectIds: number[] = [];
  uncheckedProjectIds: number[] = [];
  projdetalall: any;
  userboxdet: any;
  usersData = [];
  statusData = [];

  //projectsdt: Project[];
  constructor(private _sanitizer: DomSanitizer, private bsModalService: BsModalService, private SpinnerService: NgxSpinnerService, private route: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService) {
    this.baseUrl = Global.apiURL;
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      "language": {
        "lengthMenu": "Showing _MENU_ Link(s)",
        "paginate": {
          "first": "«",
          "previous": "‹",
          "next": "›",
          "last": "»",

        }
      },
      columnDefs: [
        { orderable: false, targets: 5 }
      ],
      pageLength: 100,
      info: false,
      processing: true,
      order: [[1, 'asc']],
      lengthMenu: [5, 10, 25, 100]
    };
    this.http.get(this.baseUrl + '/getalllinksuser.php')
      .subscribe(data => {
        this.links = data;
        this.allLinks = data;

        const userIds = Array.from(new Set(this.links.map(item => item.user_id)));
        userIds.forEach((u) => {
          var userinfo = this.links.filter(a => a.user_id === u);
          var user =
          {
            user_id: u,
            first_name: userinfo[0].first_name,
            last_name: userinfo[0].last_name,
          };
          this.usersData.push(user);
        });

        var d = Array.from(new Set(this.links.map(item => item.link_status)));
        d.forEach((ss) => {
          var dt =
          {
            link_status: ss
          };
          this.statusData.push(dt);
        });
        this.dtTrigger.next();
        this.linksUnique = Array.from(new Set(this.links.map(team => team.user_id)));
      });
    const userid = localStorage.getItem("Guid");
    const userguid = localStorage.getItem("UserID");
    const userrole = localStorage.getItem('UserRole');

    if (userid == null || userid == '' || userguid == '' || userguid == null || userrole == 'user') {
      this.router.navigate(['/login']);
    }
    this.Deactiveproj();
  }

  FilterData() {
    debugger;
    if(this.user_id == undefined || this.user_id == null)
      {
        this.user_id="";
      } 
      if(this.link_status == undefined || this.link_status == null)
      {
        this.link_status="";
      } 
    if (this.user_id == "" && this.link_status == "") {
      this.links = this.allLinks;
    }
    else {
      if (this.user_id != "" && this.link_status != "") {
        this.links = this.allLinks.filter(a => a.user_id === Number(this.user_id)
        && a.link_status == this.link_status
       );
      }
      else if (this.user_id != "" && this.link_status == "") {
        this.links = this.allLinks.filter(a => a.user_id === Number(this.user_id)
       );
      }
      else if (this.user_id == "" && this.link_status != "") {
        this.links = this.allLinks.filter(a => a.link_status === this.link_status
       );
      }
     
    }


  }
  Deactiveproj() {
    this.userrole = localStorage.getItem("UserRole");
  }

  editLink(projid: number) {
    this.router.navigate(['/editlink'], { queryParams: { id: '' + projid + '' } });
  }
  showProject(userid: number) {
    this.router.navigate(['/user-profile'], { queryParams: { userid: '' + userid + '' } });
  }
  deleteLink(id: number, title: string) {
    debugger;
    this.bsModalRef = this.bsModalService.show(DeletelinkComponent);
    this.bsModalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.SpinnerService.show();
        this.http.delete(this.baseUrl + '/deletelink.php?id=' + id).subscribe(data => {
          this.http.delete<any>(this.baseUrl + "/deletelinkcategories.php?user=" + localStorage.getItem("UserID") + "&link_id=" + id).subscribe();
          this.redirectTo('/linksall');
          this.SpinnerService.hide();
          this.toastr.success('Link Deleted Successfully!', 'Success!');
        });
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();
  }
  onClose() {
    this.bsModalRef.hide();

  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/linksall']));
  }
  updateprojtoggle(id, data): void {
    this.http.get<any>(this.baseUrl + "/getlinkdetails.php?id=" + id).subscribe((dataprojdet: any) => {
      this.projdetalall = dataprojdet;
      if (this.projdetalall[0].link_status == "pending") {
        const userinboxdet = {
          user_message: "Your link was approved by admin",
          user_id: this.projdetalall[0].user_id,
          user_name: localStorage.getItem("FirstName") + localStorage.getItem("LastName")
        }
        this.http.post<any>(this.baseUrl + "/addinboxmessage.php", userinboxdet).subscribe((userinbox: any) => {
          this.userboxdet = userinbox;
        },
          error => {
            console.log(error);
          });
      }
      else {

      }
    },
      error => {
        console.log(error);
      });
    this.SpinnerService.show();
    this.http.get(this.baseUrl + '/togglelinks.php?id=' + id).subscribe(
      response => {
        this.SpinnerService.hide();
        this.redirectTo('/linksall');
        this.toastr.success('Link Status Updates Successfully!', 'Success!');
      },
      error => {
        //console.log(error);
        this.toastr.error('Some Error Occurred!', 'Error!');
      });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  //   ngAfterViewInit(): void {
  //     this.dtTrigger.next();
  // }
  openimage(image) {
    if (image == '' || image == null || image == undefined) {
      window.open("https://zingbolt.com/phpapi/imagenotavaliable.jpg");
    }
    else {
      window.open(image);
    }
  }
  rowCheckBoxChecked(e, personId) {
    debugger;
    if (e.currentTarget.checked) {
      this.uncheckedProjectIds.splice(this.uncheckedProjectIds.indexOf(personId), 1);
      if (!this.gridCheckAll)
        this.checkedProjectIds.push(personId);
    }
    else {
      this.checkedProjectIds.splice(this.checkedProjectIds.indexOf(personId), 1);
      if (this.gridCheckAll)
        this.uncheckedProjectIds.push(personId);
    }
  }
  deleteProj() {
    this.bsModalRef = this.bsModalService.show(DeletelinkComponent);
    this.bsModalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        if (this.checkedProjectIds.length == 0) {
          this.toastr.error('Please select atlease one link', 'Error!');
        }
        else {
          let uniqueproje = this.checkedProjectIds.filter((v, i, a) => a.indexOf(v) === i);
          for (var i = 0; i < uniqueproje.length; i++) {
            debugger;
            this.http.delete(this.baseUrl + '/deletelink.php?id=' + uniqueproje[i])
              .subscribe(deldata => {
                debugger;
                this.http.delete<any>(this.baseUrl + "/deletelinkcategories.php?user=" + localStorage.getItem("UserID") + "&link_id=" + uniqueproje[i]).subscribe();
              });
          }
          this.redirectTo('/linksall')
          this.toastr.success('Link Deleted Successfully!', 'Success!');
        }
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();

  }
  activeProj() {
    if (this.checkedProjectIds.length == 0) {
      this.toastr.error('Please select atlease one link', 'Error!');
    }
    else {
      let activeproj = this.checkedProjectIds.filter((v, i, a) => a.indexOf(v) === i);
      console.log(activeproj);
      for (var i = 0; i < activeproj.length; i++) {
        this.http.get<any>(this.baseUrl + "/getlinkdetails.php?id=" + activeproj[i]).subscribe((dataprojdet: any) => {
          this.projdetalall = dataprojdet;
          if (this.projdetalall[0].link_status == "pending") {
            const userinboxdet = {
              user_message: "Your link was approved by admin",
              user_id: this.projdetalall[0].user_id,
              user_name: localStorage.getItem("FirstName") + localStorage.getItem("LastName")
            }
            this.http.post<any>(this.baseUrl + "/addinboxmessage.php", userinboxdet).subscribe((userinbox: any) => {
              this.userboxdet = userinbox;
            },
              error => {
                console.log(error);
              });
          }
          else {

          }
        },
          error => {
            console.log(error);
          });
        this.http.delete(this.baseUrl + '/togglelinks.php?id=' + activeproj[i]).subscribe(statusdata => {
        });
      }
      this.redirectTo('/linksall')
      this.toastr.success('Link status update successfully!', 'Success!');
    }
  }

}
