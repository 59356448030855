import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormsModule, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Categories, SocialPlatforms } from '../../../model/categories.model';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-editmyproject',
  templateUrl: './editmyproject.component.html',
  styleUrls: ['./editmyproject.component.scss']
})
export class EditmyprojectComponent implements OnInit {
  projid: number;
  baseUrlcat: string;
  public getsocial_platforms: SocialPlatforms[];
  public getcat: Categories[];
  public MyprojForm: FormGroup;
  uniqueitems: Array<string> = [];
  public post: MyProj = {
    id: 0,
    title: '',
    description: '',
    url: '',
    budget: '',
    image: ''
  }
  projectscat: any;
  loading: boolean;
  imageurl: any;
  baseUrl: string;
  projectdetail: any;
  allstatdetail: any;
  statdatacred: any;
  imageedurl: any;

  constructor(private SpinnerService: NgxSpinnerService, private toastr: ToastrService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.baseUrl = Global.apiURL;


    this.route.queryParams.subscribe(params => {
      this.projid = params['id'];
      var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', });


      this.http.get(this.baseUrl + '/getallcategories.php', { headers: reqHeader }).toPromise()
        .then((res: any) => {
          this.getcat = res as Categories[];
          this.http.get(this.baseUrl + '/getprojectcategories.php?id=' + this.projid, { headers: reqHeader }).toPromise().then((res2: any) => {
            for (var i = 0; i < res2.length; i++) {
              //this.catValsSelected.push(res2[i].cat_id);
              this.getcat.forEach(function (elem: any) {
                var elemid = Number(elem.id);
                if (elemid == Number(res2[i].cat_id)) {
                  elem.checked = true;
                }
              });
            }

          },
            err => { }
          );

        },
          err => {
          }
        );
      this.http.get(this.baseUrl + '/getallsocialplatforms.php', { headers: reqHeader }).toPromise()
        .then((res: any) => {
          this.SpinnerService.hide();
          this.getsocial_platforms = res as SocialPlatforms[];
          this.http.get(this.baseUrl + '/getprojectsocials.php?id=' + this.projid, { headers: reqHeader }).toPromise().then((res2: any) => {
            for (var i = 0; i < res2.length; i++) {
              this.getsocial_platforms.forEach(function (elem: any) {
                var elemid = Number(elem.id);
                if (elemid == Number(res2[i].social_id)) {
                  elem.checked = true;
                }
              });

            }
          },
            err => { }
          );

        },
          err => {
          }
        );

    });
    this.MyprojForm = this.fb.group({
      id: '',
      title: '',
      description: '',
      url: '',
      allow_pinterest: true,
      budget: [10, Validators.required],
      file: '',
    });
  }
  public get half(): number {
    return Math.ceil(this.getcat?.length / 2);
  }
  public get half_social(): number {
    return Math.ceil(this.getsocial_platforms?.length / 2);
  }
  eventSocialCheck(id, e) {
    this.getsocial_platforms.forEach(function (elem: any) {
      var elemid = Number(elem.id);
      var prevValue = elem.checked;
      if (elemid == id) {
        elem.checked = !prevValue;
      }
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.MyprojForm.get('file').setValue(file);
    }
  }


  eventCheck(id, e) {
    this.getcat.forEach(function (elem: any) {
      var elemid = Number(elem.id);
      var prevValue = elem.checked;
      if (elemid == id) {
        elem.checked = !prevValue;
      }
    });

  }
  private prepareSave(): any {
    let input = new FormData();
    input.append('image', this.MyprojForm.get('file').value);
    return input;
  }
  clearFile(element) {
    element.value = "";
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projid = params['id'];
      const data = {}
      this.SpinnerService.show();
      this.http.post(this.baseUrl + '/getprojectdetails.php?id=' + this.projid, data).subscribe((res: any) => {
        this.http.get(this.baseUrl + '/getprojectcategories.php?id=' + this.projid).subscribe(data => {
          this.projectscat = data;
          this.post = res;
          if (this.post[0].image == '' || this.post[0].image == null || this.post[0].image == undefined) {
            this.imageedurl = "https://vcb.armadaservers.com/phpapi/imagenotavaliable.jpg";
          }
          else {
            this.imageedurl = this.post[0].image;
          }
          if (this.post[0].allow_pinterest == true) {
            this.MyprojForm.controls["allow_pinterest"].setValue(this.post[0].allow_pinterest);
          }
          else {
            this.MyprojForm.controls["allow_pinterest"].setValue(this.post[0].allow_pinterest);
          }
          this.MyprojForm.controls["id"].setValue(this.post[0].id);
          this.MyprojForm.controls["title"].setValue(this.post[0].title);
          this.MyprojForm.controls["description"].setValue(this.post[0].description);
          this.MyprojForm.controls["url"].setValue(this.post[0].url);
          this.MyprojForm.controls["budget"].setValue(this.post[0].budget);
          this.SpinnerService.hide();
        },
          (err) => console.log(err),
        );
      });
    });
  }
  savemyProj() {
    let getcat = this.getcat.filter(x => x.checked === true);
    var socialVals = this.getsocial_platforms.filter(x => x.checked === true);
    if (socialVals.length < 1) {
      this.toastr.error("Please select atleast one social platform.", 'Error!');
    }
    else if (getcat.length > 3) {
      this.toastr.error("You can select maximum 3 categories.", 'Error!');
    }
    else if (this.getcat.length < 1) {
      this.toastr.error("Please select atleast one category.", 'Error!');
    }
    else {
      const formModel = this.prepareSave();
      this.loading = true;
      this.http.post<any>(this.baseUrl + "/uploadfile.php", formModel).subscribe(
        (res: any) => {

          this.http.delete<any>(this.baseUrl + "/deleteprojectsocials.php?project=" + this.projid).subscribe(() => {
            socialVals.forEach(social => {
              console.log("Inserting project social: ", social.id);
              this.http.get<any>(this.baseUrl + "/insertprojectsocials.php?socialid=" + social.id + "&project=" + this.projid).subscribe(() => {
                console.log("Inserted project social: ", social.id);
              }, error => console.log(error));
            });
          }, error => console.log(error));

          this.http.delete<any>(this.baseUrl + "/deleteprojectcategories.php?user=" + localStorage.getItem("UserID") + "&project=" + this.projid).subscribe(() => {
            getcat.forEach(cat => {
              this.http.get<any>(this.baseUrl + "/insertprojectcategories.php?cat=" + cat.id + "&user=" + localStorage.getItem("UserID") + "&project=" + this.projid).subscribe(() => {
              }, error => console.log(error));
            });
          }, error => console.log(error));

          this.imageurl = res[0].url;
          if (this.imageurl != '') {
            this.http.get<any>(this.baseUrl + '/getprojectdetails.php?id=' + this.projid).subscribe(
              (res: any) => {
                this.projectdetail = res;
                const data = {
                  id: this.MyprojForm.controls["id"].value,
                  title: this.MyprojForm.controls["title"].value,
                  description: this.MyprojForm.controls["description"].value,
                  url: this.MyprojForm.controls["url"].value,
                  allow_pinterest: this.MyprojForm.controls["allow_pinterest"].value,
                  budget: this.MyprojForm.controls["budget"].value,
                  image: this.imageurl,
                }
                this.http.get<any>(this.baseUrl + "/getuserdetails.php?id=" + localStorage.getItem("UserID")).subscribe(
                  (datastat: any) => {
                    this.allstatdetail = datastat;
                    const usercreditsimg = this.allstatdetail[0].credits;
                    const userbudgetimg = data.budget

                    if (data.budget == res[0].budget) {
                    }
                    else {
                      var minusbud = data.budget - res[0].budget;
                      var attempts = (parseInt(usercreditsimg) - minusbud);
                      localStorage.setItem("UserCredits", attempts.toString());
                      const updatecreddata = {
                        credits: localStorage.getItem("UserCredits"),
                        id: localStorage.getItem("UserID")
                      }
                      this.http.post<any>(this.baseUrl + "/updateusercredits.php", updatecreddata).subscribe((dataprojcat: any) => {
                        this.statdatacred = dataprojcat;
                        const adduserstats = {
                          proj_id: data.id,
                          user_id: localStorage.getItem("UserID"),
                          credits_in: 0,
                          credits_out: data.budget
                        }
                        this.http.post<any>(this.baseUrl + "/adduserstats.php", adduserstats).subscribe((dataprojstat: any) => {
                        },
                          error => {
                            console.log(error);
                          });
                      },
                        error => {
                          console.log(error);
                        });
                    }

                    this.http.post(this.baseUrl + '/updateuserproject.php', data).subscribe(
                      (res: any) => {
                        this.post = res;
                        this.redirectTo('/myprojects');
                        this.toastr.success('Project Updated Successfully!', 'Success!');
                      },
                      (err) => console.log(err),
                    );
                  },
                  error => {
                    console.log(error);
                  }
                );
              },
              error => {
                console.log(error);
              }
            );
          }
          else {
            this.http.get<any>(this.baseUrl + '/getprojectdetails.php?id=' + this.projid).subscribe(
              (res: any) => {
                this.projectdetail = res;
                const datawiouimt = {
                  id: this.MyprojForm.controls["id"].value,
                  title: this.MyprojForm.controls["title"].value,
                  description: this.MyprojForm.controls["description"].value,
                  url: this.MyprojForm.controls["url"].value,
                  allow_pinterest: this.MyprojForm.controls["allow_pinterest"].value,
                  budget: this.MyprojForm.controls["budget"].value,
                  image: this.projectdetail[0].image,
                }
                this.http.get<any>(this.baseUrl + "/getuserdetails.php?id=" + localStorage.getItem("UserID")).subscribe(
                  (datastat: any) => {
                    this.allstatdetail = datastat;
                    const usercredits = this.allstatdetail[0].credits;
                    const userbudget = datawiouimt.budget

                    

                    if (res[0].budget == datawiouimt.budget) {

                    }
                    else {
                      var minusbudne = datawiouimt.budget - res[0].budget;
                      var attempts = (parseInt(usercredits) - minusbudne);
                      localStorage.setItem("UserCredits", attempts.toString());
                      const updatecreddata = {
                        credits: localStorage.getItem("UserCredits"),
                        id: localStorage.getItem("UserID")
                      }
                      this.http.post<any>(this.baseUrl + "/updateusercredits.php", updatecreddata).subscribe((dataprojcat: any) => {
                        this.statdatacred = dataprojcat;
                        const adduserstats = {
                          proj_id: datawiouimt.id,
                          user_id: localStorage.getItem("UserID"),
                          credits_in: 0,
                          credits_out: datawiouimt.budget
                        }
                        this.http.post<any>(this.baseUrl + "/adduserstats.php", adduserstats).subscribe((dataprojstat: any) => {
                        },
                          error => {
                            console.log(error);
                          });
                      },
                        error => {
                          console.log(error);
                        });
                    }

                    this.http.put(this.baseUrl + '/updateuserproject.php', datawiouimt).subscribe(
                      (res: any) => {
                        this.post = res;
                        this.redirectTo('/myprojects');
                        this.toastr.success('Project Updated Successfully!', 'Success!');
                      },
                      (err) => console.log(err),
                    );
                  },
                  error => {
                    console.log(error);
                  }
                );
              });
          }
          function isValidImageURL(str) {
            if (typeof str !== 'string') return false;
            return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
          }
        });
    }
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/myprojects']));
  }
}
export interface MyProj {
  id: number;
  title: string;
  description: string;
  url: string;
  budget: string;
  image: string;
}
