import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Global } from '../../common/global';
import {DomSanitizer} from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LinksService } from 'src/app/services/links.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  link_id: any;
  projid: any;
  linkdetail: any;
  projdetail: any;
  error: any;
  getprojcatname: any;
  baseUrl: string;
  image: any;
  shareddata:any;
  linkid: any;
  linkkeyword: any;
  sharedeta: any;
  pinValsSelected: Array<string> = [];
  projdetal: any;
  userboxdet: any;
  constructor(private linkservice: LinksService,private modalService: NgbModal,private sanitizer:DomSanitizer,private SpinnerService: NgxSpinnerService, private toastr: ToastrService, private httpClient: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.baseUrl = Global.apiURL;
  }

  ngOnInit(): void {
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");

    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }

    this.route.queryParams.subscribe(params => {
      this.link_id = params['link_id'];
      this.projid = params['id'];
      const userid = localStorage.getItem("UserID");
      this.SpinnerService.show();
      //Project
      if(this.projid != undefined )
        {
          this.httpClient.get<any>(this.baseUrl + "/getsingleprojectsdata.php?proj_id=" + this.projid + "&user_id=" + userid).subscribe((dataprojdet: any) => {
            this.projdetail = dataprojdet;
            if (dataprojdet.length > 0) {
              for (var i = 0; i < dataprojdet.length; i++) {
                var Categories = dataprojdet[i].Categories;
                var catArr = Categories.split(',');
                var newcatArr = [];
                for (var j = 0; j < catArr.length; j++) {
                  newcatArr.push(
                    '<span class="badge badge-primary" (click)="setActiveProject(' + dataprojdet[i].id + ',\'' + catArr[j] + '\')">' + catArr[j] + '</span>'
                  );
                }
                dataprojdet[i].Categories = newcatArr;
              }
            }
          });
        }
      
      //Link
      this.httpClient.get<any>(this.baseUrl + "/getsinglelinksdata.php?link_id=" + this.link_id + "&user_id=" + userid).subscribe((data: any) => {
        this.linkdetail = data;
        if (data.length > 0) {
          this.httpClient.get<any>(this.baseUrl + "/getsinglelinksdatawithusers.php?link_id=" + this.link_id).subscribe((data1: any) => {
            this.shareddata=data1;
          },
          error => {
          });
          for (var i = 0; i < data.length; i++) {
            //
            var sharedURL = data[i].pin_url.split(',');
            debugger;
            var sharedLinks = [];
            for (var k = 0; k < sharedURL.length; k++) {
              sharedLinks.push(
                '<a target="_blank" href="'+sharedURL[k]+'" >' + sharedURL[k] + '</a>'
              );
            }
            data[i].pin_url = sharedLinks;
            var catArr = data[i].Categories.split(',');
            var newcatArr = [];
            for (var j = 0; j < catArr.length; j++) {
              newcatArr.push(
                '<span class="badge badge-primary" (click)="setActiveProject(' + data[i].id + ',\'' + catArr[j] + '\')">' + catArr[j] + '</span>'
              );
            }
            data[i].Categories = newcatArr;
          }
        }
      });
      
    });
  }
  pinterest(title: string, projid: number) {
    const projtitle = title;
    const data = {}
    this.httpClient.post<any>(this.baseUrl + "/getprojectdetails.php?id=" + projid, data).subscribe((dataprojcat: any) => {
      this.getprojcatname = dataprojcat;
      this.image = dataprojcat[0].image;
      this.router.navigate(['/share-project'], { queryParams: { id: '' + projid + '' } });
      window.open("http://pinterest.com/pin/create/button/?url=" + projtitle + "&media=" + this.image + "&method=button", "_blank");
    },
      error => {
        if (error.status == 401)
          this.error = error.error[0].message;
      });

  }
  addPintModal(addPintModalContent: any, linkid, link) {
    this.linkid = linkid;
    this.modalService.open(addPintModalContent, { size: 'sm' });
  }
  ShareAllow(value) {
    if (value == 1) return 'btn btn-danger btn-mini'
    else if (value == 0) return 'btn btn-light d-none'
  }
  SharedCount(value) {
    if (value == 0) return 'd-none'
  }
  addskipLink(addskipModalContent: any, linkid, keyword) {
    this.linkid = linkid;
    this.linkkeyword = keyword;
    this.modalService.open(addskipModalContent);
  }
  getpinURL(pinurl) {
    if (pinurl == '') return 'text-muted'
    else if (pinurl == null) return 'text-muted'
    else if (pinurl == undefined) return 'text-muted'
    else if (pinurl == pinurl) return 'text-muted disabled'
  }
  getpinURL1(pinurl) {
    if (pinurl == '') return 'text-muted'
    else if (pinurl == null) return 'text-muted'
    else if (pinurl == undefined) return 'text-muted'
    // else if (pinurl == pinurl) return 'text-muted disabled'
  }
  getpinIMG(pinimg) {
    if (pinimg == '') return 'img-thumbnail rounded mx-auto d-block disableimg'
    else if (pinimg == null) return 'img-thumbnail rounded mx-auto d-block disableimg'
    else if (pinimg == undefined) return 'img-thumbnail rounded mx-auto d-block disableimg'
    else if (pinimg != '') return 'img-thumbnail rounded mx-auto d-block'
  }
  getprojeimage(projimg) {
    if (projimg == '') return 'img-thumbnail rounded mx-auto d-block disimage'
    else if (projimg == null) return 'img-thumbnail rounded mx-auto d-block disimage'
    else if (projimg == undefined) return 'img-thumbnail rounded mx-auto d-block disimage'
    else if (projimg != '') return 'img-thumbnail rounded mx-auto d-block'
  }
  getYourProj(userid) {
    const userlocid = localStorage.getItem("UserID");
    if (userid == userlocid) return 'text-muted'
    if (userid != userlocid) return 'text-muted disabled'
  }
  inserturl(pinurl, data, pjid) {

    const checdat = {}
    this.httpClient.post<any>(this.baseUrl + "/getallsharepindetailsLinks.php", checdat).subscribe((shareprojcat: any) => {
      this.sharedeta = shareprojcat;

      console.log(this.sharedeta);
      const usersid = localStorage.getItem("UserID");
      for (var i = 0; i < shareprojcat.length; i++) {
        this.pinValsSelected.push(shareprojcat[i].pin_url);
      }
      let uniquefilElements = this.pinValsSelected.filter((v, i, a) => a.indexOf(v) === i);
      if (uniquefilElements.includes(pinurl)) {
        this.toastr.error('Invalid URL.Please share valid URL..!', 'Error!');
      }
      else {

        this.httpClient.get<any>(this.baseUrl + "/getlinkdetails.php?id=" + pjid).subscribe((dataprojdet: any) => {
          this.projdetal = dataprojdet;
          console.log(this.projdetal);

          const userinboxdet = {
            user_message: "Congrats! Your Article Was Shared",
            user_id: this.projdetal[0].user_id,
            user_name: localStorage.getItem("FirstName") + localStorage.getItem("LastName")
          }
          this.httpClient.post<any>(this.baseUrl + "/addinboxmessage.php", userinboxdet).subscribe((userinbox: any) => {
            this.userboxdet = userinbox;

          },
            error => {
              console.log(error);
            });
          const addshareprojectdet = {
            link_id: pjid,
            user_id: localStorage.getItem("UserID"),
            pin_url: pinurl
          }

          this.httpClient.post<any>(this.baseUrl + "/addsharelinkdetails.php", addshareprojectdet).subscribe((dataprojcat: any) => {

          },
            error => {
              console.log(error);
            });
          const addsharedetail = {
            link_id: pjid,
            user_id: localStorage.getItem("UserID"),
            name: localStorage.getItem("FirstName") + " " + localStorage.getItem("LastName"),
            pin_url: pinurl,
            link_keyword: dataprojdet[0].keyword,
            cost_pin: dataprojdet[0].budget,
            report_abuse: 0,
            link_status: dataprojdet[0].link_status,
            allow_pinterest: dataprojdet[0].allow_pinterest
          }

          this.httpClient.post<any>(this.baseUrl + "/addsharingdetailsLink.php", addsharedetail).subscribe((dataprojcat: any) => {

          },
            error => {
              console.log(error);
            });
            // Update Credits

          // this.httpClient.get<any>(this.baseUrl + "/getuserdetails.php?id=" + localStorage.getItem("UserID")).subscribe(
          //   (userdata: any) => {

          //     this.detdetail = userdata;
          //     var attempts = (parseInt(this.detdetail[0].credits) + 1);
          //     localStorage.setItem("UserCredits", attempts.toString());
          //     const updatecreddata = {
          //       credits: localStorage.getItem("UserCredits"),
          //       id: localStorage.getItem("UserID")
          //     }

          //     this.httpClient.post<any>(this.baseUrl + "/updateusercredits.php", updatecreddata).subscribe((dataprojcat: any) => {

          //     },
          //       error => {
          //         console.log(error);
          //       });

          //   },
          //   error => {
          //     this.SpinnerService.hide();
          //     console.log(error);
          //   }
          // );
          // if (pinurl.includes('www.pinterest.com')) {
          if (pinurl != '') {

            this.linkservice.updateurl(pjid, pinurl, data).subscribe(response => {
              // const updateuserstats = {
              //   proj_id: pjid,
              //   user_id: localStorage.getItem("UserID"),
              //   credits_in: 1,
              //   credits_out: 0
              // }

              // this.httpClient.post<any>(this.baseUrl + "/adduserstats.php", updateuserstats).subscribe((dataprojstat: any) => {

              // },
              //   error => {
              //     console.log(error);
              //   });
              // this.toastr.success('You have earned 1 credit. Your credits will be updated in few moments, otherwise, please refresh the page.', 'Success!');
              this.toastr.success('Wait for admin verification, You will earn 1 credit, Your credits will be updated in few moments', 'Success!');
              window.location.reload();
              //.setActiveLink(-1, 'all');
              // this.pageOfItems.forEach(el => {
              //   if (el.id == pjid) {
              //     el.skip = '1';
              //   }
              // });
              this.SpinnerService.hide();
            },
              error => {
                console.log(error);
              });
          }
          else {
            // this.toastr.error('Not a Pinterest URL!', 'Error!');
            this.toastr.error('Error while updating your given URL!', 'Error!');
          }
        },
          error => {
            console.log(error);
          });
      }
    });


  }
  skipLink(linkid) {
    const skipdata = {}
    const projserid = localStorage.getItem("UserID");
    //insertskiplink.php
    this.httpClient.post<any>(this.baseUrl + "/insertskiplink.php?user_id=" + projserid + "&link_id=" + linkid, skipdata).subscribe((skipproject: any) => {
      // this.skippedproj = skipproject;
      this.toastr.success('Link added in the skipped Links', 'Success!');
      this.router.navigate(['/linksdashboard']);
      //this.setActiveLink(-1, "");
      // this.pageOfItems.forEach(el => {
      //   if (el.id == linkid) {
      //     el.skip = '1';
      //   }
      // });
    }),
      error => {
        if (error.status == 401)
          this.error = error.error[0].message;
      }
  }
}
