import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ProjectsDisp } from '../../model/categories.model';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '../../services/project.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Global } from '../../common/global';
declare const google: any;

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {

  listdisproj: ProjectsDisp[] = [];
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userroledea: string;
  bsModalRef: BsModalRef;
  event: EventEmitter<any> = new EventEmitter
  dtTrigger: Subject<any> = new Subject<any>();
  baseUrl: string;
  userdetail: any;

  constructor(private toastr: ToastrService, private bsModalService: BsModalService, private httpClient: HttpClient, private SpinnerService: NgxSpinnerService, private router: Router,) {
    this.baseUrl = Global.apiURL;
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      "language": {
        "paginate": {
          "first": "«",
          "previous": "‹",
          "next": "›",
          "last": "»"

        }
      },
      pageLength: 100,
      info: false,
      processing: true,
      order: [[4, 'asc']],
      lengthMenu: [5, 10, 25, 50, 100]
    };
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");
    this.SpinnerService.show();
    this.httpClient.get<any>(this.baseUrl + "/getinboxdetails.php?user_id=" + userid).subscribe((inboxdata : any) => {
      this.listdisproj = inboxdata;
        this.SpinnerService.hide();
        this.dtTrigger.next();
      },
      error => {
        console.log(error);
      }
    );
    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }
    this.httpClient.get<any>(this.baseUrl + "/getuserdetails.php?id=" + userid).subscribe(
      (userdata : any) => {
        this.userdetail = userdata;
      },
      error => {
        console.log(error);
      }
    );
  }

  deleteentry(id: number) {
    this.httpClient.delete(this.baseUrl + '/deleteinbox.php?id=' + id).subscribe(datadel => {
          this.redirectTo('/inbox');
          this.toastr.success('Message successfully deleted.', 'Success!');
        });

  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/inbox']));
  }
}
