import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../common/global';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  private gapiLoaded: boolean = false;
  baseUrl: string;

  constructor(private zone: NgZone,private http: HttpClient, private router: Router, private toastr: ToastrService) {
    this.loadGapi();
    this.baseUrl = Global.apiURL;
  }

  private loadGapi(): void {
    if (!this.gapiLoaded) {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/platform.js';
      script.onload = () => {
        this.gapiLoaded = true;
      };
      document.head.appendChild(script);
    }
  }

  public authenticateUser(clientId: string, callback: EventEmitter<any>): void {
    if (this.gapiLoaded) {
      this.initAuth(clientId, callback);
    } else {
      const interval = setInterval(() => {
        if (this.gapiLoaded) {
          clearInterval(interval);
          this.initAuth(clientId, callback);
        }
      }, 100);
    }
  }

  private initAuth(clientId: string, callback: EventEmitter<any>): void {
    gapi.load('auth2', () => {
      const auth2 = gapi.auth2.init({ client_id: clientId, scope: 'profile email', plugin_name:'zingbolt' });
      const element = document.getElementById('google-login-button');
      auth2.attachClickHandler(element, {}, (googleUser: any) => {
        const profile = googleUser.getBasicProfile();

        var google_login = {
          first_name: profile.getGivenName(),
          last_name: profile.getFamilyName(),
          email: profile.getEmail(),
          photo: profile.getImageUrl(),
        };
        this.GoogleLogin(google_login);
        callback.emit(googleUser);
      }, (error: any) => {
        alert(JSON.stringify(error, undefined, 2));
      });
    });
  }
  GoogleLogin(data: any): void {
    this.http.post(this.baseUrl + '/google_login.php', data).subscribe(
      (response: any) => {
        debugger;
        if (response.status_code == 200) {
          localStorage.setItem('UserID', response.id);
          localStorage.setItem('Guid', response.guid);
          localStorage.setItem('FirstName', response.first_name);
          localStorage.setItem('LastName', response.last_name);
          localStorage.setItem('UserEmail', response.email);
          localStorage.setItem('UserRole', response.role);
          localStorage.setItem('UserCredits', response.credits);
          localStorage.setItem('CreatedAt', response.createdAt);
          localStorage.setItem('Photo', response.photo);
          localStorage.setItem('Address', response.Address);
          this.zone.run(() => {
            this.router.navigate(['/dashboard']);
        });
          this.toastr.success('User Registered Successfully!', 'Success!');
        }
        if (response.status_code == 201) {
          localStorage.setItem('UserID', response.id);
          localStorage.setItem('Guid', response.guid);
          localStorage.setItem('FirstName', response.first_name);
          localStorage.setItem('LastName', response.last_name);
          localStorage.setItem('UserEmail', response.email);
          localStorage.setItem('UserRole', response.role);
          localStorage.setItem('UserCredits', response.credits);
          localStorage.setItem('CreatedAt', response.createdAt);
          localStorage.setItem('Photo', response.photo);
          localStorage.setItem('Address', response.Address);
          this.zone.run(() => {
            this.router.navigate(['/dashboard']);
        });

        }
        if (response.status_code == 500) {
          this.toastr.error('An error occurred, please try again later.', 'Error!');
        }
      },
      error => {
        console.error('There was an error!', error);
        // Handle error response here
      }
    );
  }

  public userLogout(callback: Function): void {
    // const homeUrl = "http://localhost:4200";
    // const logoutUrl = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${homeUrl}`;
    // document.location.href = logoutUrl;
    callback();
  }
}
