import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormsModule, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Categories } from '../../../model/categories.model';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-editproject',
  templateUrl: './editproject.component.html',
  styleUrls: ['./editproject.component.scss']
})
export class EditprojectComponent implements OnInit {
  projid: number;
  baseUrlcat: string;
  catVals: Array<string> = [];
  catValsSelected: Array<string> = [];
  public getcat: Categories[];
  public MyprojForm: FormGroup;
  uniqueitems: Array<string> = [];
  public post: MyProj = {
    id: 0,
    title: '',
    description: '',
    url: '',
    budget : '',
    image : ''
  }
  projectscat: any;
  loading: boolean;
  imageurl: any;
  baseUrl: string;
  projectdetail: any;
  allstatdetail: any;
  statdatacred: any;
    imageedurl: any;

  constructor(private SpinnerService: NgxSpinnerService, private toastr: ToastrService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.baseUrl = Global.apiURL;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http.get(this.baseUrl + '/getallcategories.php', { headers: reqHeader }).toPromise()
      .then((res: any) => {
        this.getcat = res as Categories[]
      },
        err => {
        }
      );
    this.route.queryParams.subscribe(params => {
      this.projid = params['id'];
      this.http.get(this.baseUrl + '/getprojectcategories.php?id=' + this.projid)
        .subscribe((res : any) => {
          for (var i = 0; i < res.length; i++) {
            this.catValsSelected.push(res[i].cat_id);
          }
        });
    });

    this.MyprojForm = this.fb.group({
      id: '',
      title: '',
      description: '',
      url: '',
      allow_pinterest: true,
      budget: [10, Validators.required],
      file: '',
      image: ''
    });
  }
  public get half(): number {
    return Math.ceil(this.getcat?.length / 2);
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.MyprojForm.get('file').setValue(file);
      //var reader = new FileReader();
      //reader.readAsDataURL(event.tareget.name[0])
      //reader.onload = (event: any) => {
      //  this.imageedurl = event.target.result
      //}
      }
    }
  checkChecked(id) {
    var index = this.catValsSelected.indexOf(id, 0);
    //debugger;
    if (index > -1) {
      this.catVals.push(id);
      return true;
    } else
      return false;
  }
  eventCheck(id, e) {
    debugger;
    var index = this.catVals.indexOf(id, 0);
    if (index > -1)
      this.catVals.splice(index, 1);
    else
        this.catVals.push(id);
  }
  private prepareSave(): any {
    let input = new FormData();
    input.append('image', this.MyprojForm.get('file').value);
    return input;
  }
  clearFile(element) {
    element.value = "";
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projid = params['id'];
      const data = {}
      this.SpinnerService.show();
      this.http.post(this.baseUrl + '/getprojectdetails.php?id=' + this.projid, data).subscribe(
        (res: any) => {
          this.http.get(this.baseUrl + '/getprojectcategories.php?id=' + this.projid).subscribe(data => {
            this.projectscat = data;
            this.post = res;
            if (this.post[0].image == '' || this.post[0].image == null || this.post[0].image == undefined) {
              this.imageedurl = "https://vcb.armadaservers.com/phpapi/imagenotavaliable.jpg";
            }
            else {
              this.imageedurl = this.post[0].image;
            }
            this.MyprojForm.controls["id"].setValue(this.post[0].id);
            this.MyprojForm.controls["title"].setValue(this.post[0].title);
            this.MyprojForm.controls["description"].setValue(this.post[0].description);
            this.MyprojForm.controls["url"].setValue(this.post[0].url);
            this.MyprojForm.controls["allow_pinterest"].setValue(this.post[0].allow_pinterest);
            this.MyprojForm.controls["budget"].setValue(this.post[0].budget);
            this.SpinnerService.hide();
          },
            (err) => console.log(err),
          );
        });
    });
  }

  savemyProj() {
    let uniqueitems = this.catVals.filter((v, i, a) => a.indexOf(v) === i);
    if (uniqueitems.length > 3) {
      this.toastr.error("You can select maximum 3 categories.", 'Error!');
    }
    else if (!this.catVals.length) {
      this.toastr.error("Please select atleast one category.", 'Error!');
    }
    else {
      const formModel = this.prepareSave();
      this.loading = true;
      this.http.post<any>(this.baseUrl + "/uploadfile.php", formModel).subscribe(
        (res: any) => {
          this.imageurl = res[0].url;
          if (this.imageurl != '') {
              const data = {
                id: this.MyprojForm.controls["id"].value,
                title: this.MyprojForm.controls["title"].value,
                description: this.MyprojForm.controls["description"].value,
                url: this.MyprojForm.controls["url"].value,
                allow_pinterest: this.MyprojForm.controls["allow_pinterest"].value,
                budget: this.MyprojForm.controls["budget"].value,
                image: this.imageurl,
              }
              this.http.get<any>(this.baseUrl + '/getprojectdetails.php?id=' + this.projid).subscribe(
                (res: any) => {
                  this.projectdetail = res;
                  this.http.get<any>(this.baseUrl + "/getuserdetails.php?id=" + localStorage.getItem("UserID")).subscribe(
                    (datastat: any) => {
                      this.allstatdetail = datastat;
                      const usercredits = this.allstatdetail[0].credits;
                      const userbud = data.budget;
                      let uniqueElements = this.catVals.filter((v, i, a) => a.indexOf(v) === i);
                      if (uniqueElements == this.catValsSelected) { }
                      else {
                        this.http.delete<any>(this.baseUrl + "/deleteprojectcategories.php?user=" + localStorage.getItem("UserID") + "&project=" + data.id).subscribe();
                        for (var i = 0; i < uniqueElements.length; i++) {
                          this.http.get<any>(this.baseUrl + "/insertprojectcategories.php?cat=" + uniqueElements[i] + "&user=" + localStorage.getItem("UserID") + "&project=" + data.id).subscribe();
                        }
                      }
                      this.http.post(this.baseUrl + '/updateuserproject.php', data).subscribe(
                        (res: any) => {
                          this.post = res;
                          this.redirectTo('/projectsall');
                          this.toastr.success('Project Updated Successfully!', 'Success!');
                        },
                        (err) => console.log(err),
                      );
                    },
                    error => {
                      console.log(error);
                    }
                  );
                });
            }
            else {
              this.http.get<any>(this.baseUrl + '/getprojectdetails.php?id=' + this.projid).subscribe(
                (res: any) => {
                  this.projectdetail = res;
                  const data = {
                    id: this.MyprojForm.controls["id"].value,
                    title: this.MyprojForm.controls["title"].value,
                    description: this.MyprojForm.controls["description"].value,
                    url: this.MyprojForm.controls["url"].value,
                    allow_pinterest: this.MyprojForm.controls["allow_pinterest"].value,
                    budget: this.MyprojForm.controls["budget"].value,
                    image: this.projectdetail[0].image,
                  }
                  this.http.get<any>(this.baseUrl + "/getuserdetails.php?id=" + localStorage.getItem("UserID")).subscribe(
                    (datastat: any) => {
                      this.allstatdetail = datastat;
                      const usercredits = this.allstatdetail[0].credits;
                      const userbud = data.budget;
                      let uniqueElements = this.catVals.filter((v, i, a) => a.indexOf(v) === i);
                      if (uniqueElements == this.catValsSelected) {
                      }
                      else {
                        this.http.delete<any>(this.baseUrl + "/deleteprojectcategories.php?user=" + localStorage.getItem("UserID") + "&project=" + data.id).subscribe();
                        for (var i = 0; i < uniqueElements.length; i++) {
                          this.http.get<any>(this.baseUrl + "/insertprojectcategories.php?cat=" + uniqueElements[i] + "&user=" + localStorage.getItem("UserID") + "&project=" + data.id).subscribe();
                        }
                      }
                      this.http.put(this.baseUrl + '/updateuserproject.php', data).subscribe(
                        (res: any) => {
                          this.post = res;
                          this.redirectTo('/projectsall');
                          this.toastr.success('Project Updated Successfully!', 'Success!');
                        },
                        (err) => console.log(err),
                      );
                    },
                    error => {
                      console.log(error);
                    }
                  );
                });
            }
        }
      );
    }
    function isValidImageURL(str) {
      if (typeof str !== 'string') return false;
      return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
    }
    function ValidImageURL(str) {
      if (typeof str !== 'string') return false;
      return !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
    }
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/projectsall']));
  }
}

export interface MyProj {
  id: number;
  title: string;
  description: string;
  url: string;
  budget: string;
  image: string;
}
