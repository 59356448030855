import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ProjectsDisp } from '../../model/categories.model';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '../../services/project.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Global } from '../../common/global';
import { QueuedeleteprojectComponent } from './queuedeleteproject/queuedeleteproject.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare const google: any;

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit {
  listdisproj: ProjectsDisp[] = [];
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userroledea: string;
  bsModalRef: BsModalRef;
  event: EventEmitter<any> = new EventEmitter
  dtTrigger: Subject<any> = new Subject<any>();
  baseUrl: string;
    projectid: any;
    projecttitle: any;
    skipproj: any;
    error: any;
  constructor(private modalService: NgbModal,private toastr: ToastrService, private bsModalService: BsModalService, private httpClient: HttpClient, private SpinnerService: NgxSpinnerService, private router: Router,) {
    this.baseUrl = Global.apiURL;
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      "language": {
        "paginate": {
          "first": "«",
          "previous": "‹",
          "next": "›",
          "last": "»"

        }
      },
      pageLength: 100,
      info: false,
      processing: true,
      order: [[4, 'desc']],
      lengthMenu: [5, 10, 25, 50,100]
    };
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");
    this.SpinnerService.show();
    console.log(this.baseUrl + "/getmyskipprojects.php?userid=" + userid);
    this.httpClient.get<any>(this.baseUrl + "/getmyskipprojects.php?userid=" + userid).subscribe(
      data => {
        this.listdisproj = data;
        this.dtTrigger.next();
        this.SpinnerService.hide();
      },
      error => {
        this.SpinnerService.hide();
        console.log(error);
      }
    );
    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }
    this.Deactiveproj();
    //let map = document.getElementById('map-canvas');
    //let lat = map.getAttribute('data-lat');
    //let lng = map.getAttribute('data-lng');
    //var myLatlng = new google.maps.LatLng(lat, lng);
    //var mapOptions = {
    //  zoom: 12,
    //  scrollwheel: false,
    //  center: myLatlng,
    //  mapTypeId: google.maps.MapTypeId.ROADMAP,
    //  styles: [
    //    { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#444444" }] },
    //    { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] },
    //    { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] },
    //    { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] },
    //    { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] },
    //    { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
    //    { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] },
    //    { "featureType": "water", "elementType": "all", "stylers": [{ "color": '#5e72e4' }, { "visibility": "on" }] }]
    //}
    //map = new google.maps.Map(map, mapOptions);
    //var marker = new google.maps.Marker({
    //  position: myLatlng,
    //  map: map,
    //  animation: google.maps.Animation.DROP,
    //  title: 'Hello World!'
    //});
    //var contentString = '<div class="info-window-content"><h2>Argon Dashboard</h2>' +
    //  '<p>A beautiful Dashboard for Bootstrap 4. It is Free and Open Source.</p></div>';
    //var infowindow = new google.maps.InfoWindow({
    //  content: contentString
    //});
    //google.maps.event.addListener(marker, 'click', function () {
    //  infowindow.open(map, marker);
    //});
  }

  editmyProject(projid: number) {
    this.router.navigate(['/queueedit'], { queryParams: { id: '' + projid + '' } });
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/skipped-projects']));
  }
  deletemyProject(id: number, title: string) {
    this.bsModalRef = this.bsModalService.show(QueuedeleteprojectComponent);
    this.bsModalRef.content.event.subscribe(result => {
      if (result == 'OK') {
          this.httpClient.delete(this.baseUrl + '/deleteproject.php?id=' + id)
            .subscribe(data => {
              this.redirectTo('/queue')
              this.toastr.success('Queue Project Deleted Successfully!', 'Success!');
            });
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();
  }
  onClose() {
    this.bsModalRef.hide();

  }

  Deactiveproj() {
    this.userroledea = localStorage.getItem("UserRole");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  openimage(image) {
    if (image == '' || image == null || image == undefined) {
      window.open("https://zingbolt.com/phpapi/imagenotavaliable.jpg");
    }
    else {
      window.open(image);
    }
  }
  addskipProject(addskipModalContent: any, projid, title, userid) {
    this.projectid = projid;
    this.projecttitle = title;
    this.modalService.open(addskipModalContent);
  }
  deleteskip(projid) {
    const skipdata = {}
    const projuserid = localStorage.getItem("UserID");
    this.httpClient.delete<any>(this.baseUrl + "/deleteskipproject.php?id=" + projid + "&user_id=" + projuserid, skipdata).subscribe((skipproject: any) => {
      this.skipproj = skipproject;
      this.toastr.success('Project deleted from the skipped projects', 'Success!');
      this.redirectTo('/skipped-projects');
    }),
      error => {
        if (error.status == 401)
          this.error = error.error[0].message;
      }

  }
}
