import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Addlinks } from '../model/addlinks.model';
import { Observable } from 'rxjs';
import { Projects } from '../model/categories.model';
import { Global } from '../common/global';

@Injectable({
  providedIn: 'root'
})
export class LinksService {
  sharedaddproj: string;
  baseUrlLogin: string;
  baseUrlProj: string;
  listdisproj: Projects[] = [];
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.sharedaddproj = '';
    this.baseUrl = Global.apiURL;
  }

  formaddproject: Addlinks = new Addlinks('', 1, '', '', '', '');

  createProejct() {
    return this.http.post(this.baseUrl + '/addlinks.php', this.formaddproject);
  }
  updateurl(id,pinurl, data): Observable<any> {
    return this.http.put(this.baseUrl + '/updatepinurlLink.php?id='+id+'&pin_url='+pinurl, data);
  }

  DisplayProjects() {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const userid = localStorage.getItem("UserID");
    return this.http.get(this.baseUrl + '/getmylinks.php?user=' + userid, { headers: reqHeader }).toPromise()
      .then((res: any) => {
        console.log(res);
        this.listdisproj = res as Projects[]
        console.log(this.listdisproj);
      },
        err => {
        }
      );
  }
}
