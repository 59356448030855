import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router} from '@angular/router';
import { Global } from '../../../common/global';

@Component({
  selector: 'app-deletelink',
  templateUrl: './deletelink.component.html',
  styleUrls: ['./deletelink.component.scss']
})
export class DeletelinkComponent implements OnInit {
  event: EventEmitter<any> = new EventEmitter
  keyword: any;
  id: any;
  baseUrl: string;

  constructor(private router: Router, private bsModalRef: BsModalRef, private SpinnerService: NgxSpinnerService, private http: HttpClient, private toastr: ToastrService) {
    this.baseUrl = Global.apiURL;
  }

  deletePost() {
    this.bsModalRef.content.event.subscribe(result => {
      debugger;
      if (result == 'OK') {
        setTimeout(() => {
          this.http.delete<any>(this.baseUrl + '/deletelink.php?id=' + this.id).subscribe(data => {
              debugger;
              this.http.delete<any>(this.baseUrl + "/deletelinkcategories.php?user=" + localStorage.getItem("UserID") + "&link_id=" + this.id).subscribe();
              this.router.navigate(['/mylinks'])
              this.toastr.success('Link Deleted Successfully!', 'Success!');
            });
        }, 500);
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();
  }

  onClose() {
    this.bsModalRef.hide();

  }
  ngOnInit(): void {
  }

}
