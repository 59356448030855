import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { LinksDips } from '../../model/categories.model';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '../../services/project.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Global } from '../../common/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare const google: any;

@Component({
  selector: 'app-linkskipped',
  templateUrl: './linkskipped.component.html',
  styleUrls: ['./linkskipped.component.scss']
})
export class LinkskippedComponent implements OnInit {
  listdislinks: LinksDips[] = [];
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userroledea: string;
  bsModalRef: BsModalRef;
  event: EventEmitter<any> = new EventEmitter
  dtTrigger: Subject<any> = new Subject<any>();
  baseUrl: string;
    linkid: any;
    linkkeyword: any;
    skipproj: any;
    error: any;
  constructor(private modalService: NgbModal,private toastr: ToastrService, private bsModalService: BsModalService, private httpClient: HttpClient, private SpinnerService: NgxSpinnerService, private router: Router,) {
    this.baseUrl = Global.apiURL;
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      "language": {
        "lengthMenu": "Showing _MENU_ Link(s)",
        "paginate": {
          "first": "«",
          "previous": "‹",
          "next": "›",
          "last": "»"

        }
      },
      pageLength: 100,
      info: false,
      processing: true,
      order: [[4, 'desc']],
      lengthMenu: [5, 10, 25, 50,100]
    };
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");
    this.SpinnerService.show();
    // console.log(this.baseUrl + "/getmyskiplinks.php?userid=" + userid);
    this.httpClient.get<any>(this.baseUrl + "/getmyskiplinks.php?userid=" + userid).subscribe(
      data => {
        this.listdislinks = data;
        this.dtTrigger.next();
        this.SpinnerService.hide();
      },
      error => {
        this.SpinnerService.hide();
        console.log(error);
      }
    );
    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }
    this.Deactiveproj();
   
  }


  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/skipped-links']));
  }

  onClose() {
    this.bsModalRef.hide();

  }

  Deactiveproj() {
    this.userroledea = localStorage.getItem("UserRole");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  addskipProject(addskipModalContent: any, linkid, keyword, userid) {
    this.linkid = linkid;
    this.linkkeyword = keyword;
    this.modalService.open(addskipModalContent);
  }
  deleteskip(linkid) {
    const skipdata = {}
    const projuserid = localStorage.getItem("UserID");
    this.httpClient.delete<any>(this.baseUrl + "/deleteskiplink.php?id=" + linkid + "&user_id=" + projuserid, skipdata).subscribe((skipproject: any) => {
      this.skipproj = skipproject;
      this.toastr.success('Link deleted from the skipped link', 'Success!');
      this.redirectTo('/skipped-links');
    }),
      error => {
        if (error.status == 401)
          this.error = error.error[0].message;
      }

  }
}
