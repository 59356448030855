import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ProjectsDisp } from '../../model/categories.model';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '../../services/project.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Global } from '../../common/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeletemylinksComponent } from './deletemylinks/deletemylinks.component';
declare const google: any;

@Component({
  selector: 'app-mylinks',
  templateUrl: './mylinks.component.html',
  styleUrls: ['./mylinks.component.scss']
})
export class MylinksComponent implements OnInit {
  listdisproj: ProjectsDisp[] = [];
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  userroledea: string;
  bsModalRef: BsModalRef;
  event: EventEmitter<any> = new EventEmitter
  dtTrigger: Subject<any> = new Subject<any>();
  baseUrl: string;
    projectid: any;
    projecttitle: any;
    skipproj: any;
    error: any;
  constructor(private modalService: NgbModal,private toastr: ToastrService, private bsModalService: BsModalService, private httpClient: HttpClient, private SpinnerService: NgxSpinnerService, private router: Router,) {
    this.baseUrl = Global.apiURL;
  }
  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      "language": {
        "paginate": {
          "first": "«",
          "previous": "‹",
          "next": "›",
          "last": "»"

        }
      },
      pageLength: 100,
      info: false,
      processing: true,
      order: [[0, 'desc']],
      lengthMenu: [5, 10, 25, 50,100]
    };
    const userguid = localStorage.getItem("Guid");
    const userid = localStorage.getItem("UserID");
    this.SpinnerService.show();
    this.httpClient.get<any>(this.baseUrl + "/getalllinksuser.php").subscribe(
      data => {
        var u = parseInt(userid);
        this.listdisproj = data;
        this.listdisproj= this.listdisproj.filter(item => item.user_id == u);
        this.dtTrigger.next();
        this.SpinnerService.hide();
      },
      error => {
        this.SpinnerService.hide();
        console.log(error);
      }
    );
    if (userid == null || userid == '' || userguid == '' || userguid == null) {
      this.router.navigate(['/login']);
    }
    this.Deactiveproj();
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/mylinks']));
  }
  editmyProject(projid: number) {
    this.router.navigate(['/editmylink'], { queryParams: { id: '' + projid + '' } });
  }
  deletemyProject(id: number, title: string) {
    this.bsModalRef = this.bsModalService.show(DeletemylinksComponent);
    this.bsModalRef.content.event.subscribe(result => {
      if (result == 'OK') {
        this.httpClient.delete(this.baseUrl + '/deletelink.php?id=' + id).subscribe(data => {
              this.redirectTo('/mylinks');
              this.toastr.success('Link Deleted Successfully!', 'Success!');
          });
        
      }
    });
    this.event.emit('OK');
    this.bsModalRef.hide();
  }
  onClose() {
    this.bsModalRef.hide();

  }

  Deactiveproj() {
    this.userroledea = localStorage.getItem("UserRole");
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  openimage(image) {
    if (image == '' || image == null || image == undefined) {
      window.open("https://zingbolt.com/phpapi/imagenotavaliable.jpg");
    }
    else {
      window.open(image);
    }
  }

  
 
}
